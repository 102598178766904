function show_div_with_hidden_question(div) {
  div.show();
  const hiddenQuestion = div.find('input');
  hiddenQuestion.prop('disabled', false);
  hiddenQuestion.prop('required', true);
}

function hide_div_with_hidden_question(div) {
  div.hide();
  const hiddenQuestion = div.find('input');
  hiddenQuestion.prop('required', false);
  hiddenQuestion.prop('disabled', true);
}

export function initializeHiddenQuestions() {
  $("div[id^='enable_question']").each(function() {
    const otherQuestionDiv = $(this);
    const id = otherQuestionDiv.attr('id');
    const capturedInfo = id.match(/enable_question_(\d+)_on_(\d+)_(.+)/);
    const optionId = capturedInfo[1];
    const questionId = capturedInfo[2];
    const questionType = capturedInfo[3];

    const hiddenQuestion = otherQuestionDiv.find('input');
    hiddenQuestion.prop('required', false);
    hiddenQuestion.prop('disabled', true);
    switch (questionType) {
      case 'radio':
        $('input[id^="answers_' + questionId + '_"').each(function() {
        const radioElem = $(this);
        const radioCheck = () => {
          if (radioElem.is(':checked') && radioElem.val() === optionId) {
            show_div_with_hidden_question(otherQuestionDiv);
          } else {
            hide_div_with_hidden_question(otherQuestionDiv);
          }
        };
        radioCheck();
        $(this).change(radioCheck);
      });
      break;
      /*eslint-disable no-case-declarations*/
      case 'select':
        const selectElem = $('#answers_' + questionId + '_option_ids');
      const selectCheck = () => {
        const selectedId = selectElem.val();
        if (selectedId === optionId) {
          show_div_with_hidden_question(otherQuestionDiv);
        } else {
          hide_div_with_hidden_question(otherQuestionDiv);
        }
      };
      selectCheck();
      selectElem.change(selectCheck);
      break;
      /*eslint-enable no-case-declarations*/
    }
  });
}
