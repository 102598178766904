/* FIXME: Use a better way of obtaining this constant */
/* There are 2 fixed, and 4 more necessary to the form to be sent */
const NUMBER_OF_NECESSARY_CHECKBOXES = 4;

export class EssentialCompetences {
  static checkForSubmitButton = () => {
    const shouldBeDisabled = $('#competences .compasso-checked').length !== NUMBER_OF_NECESSARY_CHECKBOXES;
    $(':submit').prop('disabled', shouldBeDisabled);
    if (shouldBeDisabled) {
      $('#warning-text').show();
    } else {
      $('#warning-text').hide();
    }
  }

  static initializeEssentialCompetences = () => {
    $('#competences .compasso-checkbox').each(EssentialCompetences.associateWithCheckForSubmit);
    EssentialCompetences.checkForSubmitButton();
  }

  static associateWithCheckForSubmit = (_index: number, element: HTMLElement) => {
    $(element).on('click', EssentialCompetences.checkForSubmitButton);
  }
}
