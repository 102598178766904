import {IDGenerator} from "../modules/idGenerator";
import {ResponsibilityInput} from "./responsibilityInput";

export class NewResponsibilityPage {
  idGenerator: IDGenerator

  constructor() {
    this.idGenerator = new IDGenerator();
    this.fetchResponsibilities();
    this.initializeResponsibilityInputs();
  }

  fetchResponsibilities = () => {
    $("#add-responsibility").click((): void => {
      const responsibilityID = this.idGenerator.next();
      $.ajax("/responsibilities/form/" + responsibilityID, {
        success: (data: string): void => {
          $("#responsibility-form").replaceWith(data);
          $("#add-responsibility").hide();
          const input = new ResponsibilityInput(responsibilityID);
          input.activate();
        }
      });
    });
  }

  initializeResponsibilityInputs = () => {
    $((): void => {
      $(".responsibility-form").each((idx, elem: Element): void => {
        const responsibilityID = parseInt(elem.getAttribute("data-id"), 10);
        const input = new ResponsibilityInput(responsibilityID);
        input.activateRemoveResponsibilityLink();
        input.activateEditResponsibilityLink();
      })
    })
  }
}
