export class CheckBlank {
  submitButtonShouldBeDisabled: boolean;

  constructor() {
    this.submitButtonShouldBeDisabled = false;
  }

  checkInputs = () => {
    this.submitButtonShouldBeDisabled = false;
    $('.checkbox-options.required').each(this.shouldDisableButton);
    $(':submit').prop('disabled', this.submitButtonShouldBeDisabled);
  }

  shouldDisableButton = (_index: number, element: HTMLElement): void => {
    if ($(element).find(':checkbox:checked').length === 0) {
      this.submitButtonShouldBeDisabled = true;
    }
  }

  associateCheckboxInputsCallback = () => {
    $('.checkbox-options.required .compasso-checkbox').on('click', this.checkInputs);
    this.checkInputs();
  }
}
