function enableProceedUnlessInputIsBlank(buttonSelector: string) {
  if ($('input[class^=user-form]').filter(function() {
    return $.trim(String($(this).val())).length === 0
  }).length === 0) {
    $(buttonSelector).prop('disabled', false);
  } else {
    $(buttonSelector).prop('disabled', true);
  }
}

export function initializeRegistrationForm() {
  $('input[class^=user-form]').each(function() {
    $(this).on('input', () => { enableProceedUnlessInputIsBlank('#user-form-button'); });
  });

  enableProceedUnlessInputIsBlank('#user-form-button');
}

