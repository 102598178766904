export function initializeCheckboxInputs() {
  $('div.compasso-checkbox').each(function() {
    const checkboxChild = $(this).find(':checkbox')[0] as HTMLInputElement;
    if(!checkboxChild.disabled) {
      $(this).click(() => {
        checkboxChild.checked = !checkboxChild.checked;
        if(checkboxChild.checked) {
          $(this).addClass('compasso-checked');
        } else {
          $(this).removeClass('compasso-checked');
        }
      });
    }
    if(checkboxChild.checked) {
      $(this).addClass('compasso-checked');
    }
  });
}
