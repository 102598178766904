export class CitySelect {
  public static readonly CITY_ID_SELECTOR = "#location_city";
  public static readonly STATE_ID_SELECTOR = "#location_state_id";

  static associateChangeCallback(selectedCity:(string|null) = null) {
    $(this.STATE_ID_SELECTOR).change(this.refreshCities.bind(this));
    if (selectedCity) {
      this.refreshCities(() => {
        CitySelect.selectCity(selectedCity);
      });
    } else {
      this.refreshCities();
    }
  }

  static selectCity(cityName: string) {
    $(this.CITY_ID_SELECTOR).val(cityName);
  }

  static errorOnCitySelect() {
    $("#city-request-error").show();
    const cityElement = $(this.CITY_ID_SELECTOR);
    cityElement.html("");
    cityElement.prop("disabled", true);
  }

  static successfulCitySelect(data, callbackFunction=null) {
    const cityIdInput = $(CitySelect.CITY_ID_SELECTOR);
    const options: [string] = data.map((cityInfo) => {
      const cityName = cityInfo.nome;

      if (cityName === cityIdInput.attr("data_selected")) {
        return "<option value=\"" + cityName + "\" selected=\"selected\">" + cityName + "</option>";
      } else {
        return "<option value=\"" + cityName + "\">" + cityName + "</option>";
      }
    });
    cityIdInput.html(options.join("\n"));
    cityIdInput.prop("disabled", false);
    $("#city-request-error").hide();
    if (callbackFunction instanceof Function) {
      callbackFunction();
    }
  }

  static citiesRequest(stateId: string, callbackFunction=null) {
    const fullURL = "/cities/" + stateId;
    $.ajax({ url: fullURL, statusCode: {
      200: (data) => { CitySelect.successfulCitySelect(data, callbackFunction); },
      // No Content response, which should indicate an error on the Controller
      204: () => { CitySelect.errorOnCitySelect(); }
    },
    error() { CitySelect.errorOnCitySelect(); }
    });
  }

  static refreshCities(callbackFunction=null) {
    const stateId = $(CitySelect.STATE_ID_SELECTOR).val();
    if (stateId) {
      this.citiesRequest(stateId.toString(), callbackFunction);
    } else {
      const cityIdElement = $(this.CITY_ID_SELECTOR);
      cityIdElement.html("");
      cityIdElement.prop("disabled", true);
    }
  }
}
