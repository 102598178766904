export class ResponsibilityInput {
  id: number;
  selectRemove: string;
  selectDiv: string;
  selectEdit: string;
  selectNewForm: string;
  selectCancel: string;
  formURL: string;

  constructor(id: number) {
    this.id = id
    this.selectRemove = "#remove-responsibility-" + this.id;
    this.selectDiv = "#responsibility-div-" + this.id;
    this.selectEdit = "#edit-responsibility-" + this.id;
    this.selectNewForm = "#new-responsibility-form-" + this.id;
    this.selectCancel = "#cancel-responsibility-" + this.id;
    this.formURL = "/responsibilities/form/" + this.id;
  }

  activateRemoveResponsibilityLink() {
    $(this.selectRemove).click((): void => {
      $(this.selectDiv).remove();
    });
  }

  activateEditResponsibilityLink() {
    $(this.selectEdit).click((): void => {
      $("#add-responsibility").hide();
      $.get(this.formURL,
            { name: $("#responsibilities_" + this.id).attr("value") },
            (editFormData: string): void => {
              const oldDivContent = $(this.selectDiv).html();
              $(this.selectDiv).html(editFormData);

              $(this.selectCancel).click((): void => {
                $(this.selectDiv).html(oldDivContent);
                $("#add-responsibility").show();
                this.activateRemoveResponsibilityLink();
                this.activateEditResponsibilityLink();
              });

              $("#responsibility-form").remove();
              this.activate();
            }
           );
    });

  }

  activate() {
    $(this.selectCancel).click((): void => {
      $(this.selectNewForm).remove();
      $("#add-responsibility").show();
    });

    $("#new-responsibility-" + this.id).click((): void => {
      $.post(
        "/responsibilities",
        {
          authenticity_token: $("[name='csrf-token']").attr("content"),
          name: $("#responsibility-name-" + this.id).val(),
          id: this.id,
        },
        (data: string): void => {
          $(this.selectNewForm).replaceWith(data);

          this.activateRemoveResponsibilityLink();
          this.activateEditResponsibilityLink();

          $("#add-responsibility").show();
        }
      );
    });
  }
}
