// Entry point for the build script in your package.json
import "bootstrap"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'Compasso',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-GYPPJ9YDEN']
    })
  ]
})

window.ga = analytics

window.addEventListener("load", () => {
  ga.page();
});

Rails.start()
ActiveStorage.start()

import $ from "jquery";
window.$ = $;

import { CitySelect } from './locations/citySelect';
window.CitySelect = CitySelect;

import { CheckBlank } from './question_blocks/checkBlank';
window.CheckBlank = CheckBlank;

import { initializeCheckboxInputs } from './question_blocks/checkbox_questions';
window.initializeCheckboxInputs = initializeCheckboxInputs;

import { initializeHiddenQuestions } from './question_blocks/other_questions';
window.initializeHiddenQuestions = initializeHiddenQuestions;

import { EssentialCompetences } from './positions/essentialCompetences';
window.EssentialCompetences = EssentialCompetences;

import { NewResponsibilityPage } from './positions/new';
window.NewResponsibilityPage = NewResponsibilityPage;

import { maskCPF } from './users/maskCPF';
window.maskCPF = maskCPF;

import { maskPhone } from './users/maskPhone';
window.maskPhone = maskPhone;

import { initializeRegistrationForm } from './registration/form_control';
window.initializeRegistrationForm = initializeRegistrationForm;
