import 'cleave.js/dist/addons/cleave-phone.br';
import Cleave from 'cleave.js';

export function maskPhone() {
  new Cleave('#phone_number', {
    phone: true,
    phoneRegionCode: "br",
    onValueChanged: (e) => {
      document.getElementById('phone_number').value =
        e.target.value.replace(/(\d{2}) (\d+) (\d{4})/, '($1) $2-$3')
    }
  });
}
